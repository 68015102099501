import React from "react";
import * as styles from "../../styles/orderbuild/instructions.module.css";
import Text from "../SVG/TextDivider";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";

function Instructions({ operating }) {
  const data = useStaticQuery(graphql`
    {
      strapiMenuBuildPage {
        instructions
      }
    }
  `);
  return (
    <div className={styles.container}>
      <div>
        <Text>Build your menu</Text>
      </div>

      <h3>Here's how it works</h3>
      {<p>{data.strapiMenuBuildPage.instructions}</p>}
      {!operating && (
        <p
          style={{
            padding: "1rem",
            maxWidth: "85ch",
            lineHeight: "1.6",
            whiteSpace: "pre-wrap",
            color: "red",
            textAlign: "center",
          }}
        >
          WE ARE NOT OPERATING TODAY! <br />
          <br />{" "}
          <Link
            style={{
              color: "white",
              background: "#8a0d10",
              padding: ".5rem",
            }}
            to="/contact"
          >
            Contact us
          </Link>{" "}
          directly for further enquiries.
        </p>
      )}
    </div>
  );
}

export default React.memo(Instructions);
