import React from "react";
import FancyBorder from "../menu/FancyBorder";
import TextDivider from "../SVG/TextDivider1";
import * as textStyle from "../../styles/orderbuild/summary.module.css";
import * as styles from "../../styles/orderbuild/guests.module.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const Guests = ({ setMenu,numberOfGuests, onNameClickFunc }) => {
  const minimumGuests = 10;
  const handleSubmit = (values, setSubmitting) => {
    setMenu((state) => {
      return { ...state, guests: parseInt(values.numberOfGuests) };
    });
    onNameClickFunc();
    setSubmitting(false);
  };
  const Validation = Yup.object().shape({
    numberOfGuests: Yup.string()
      .required("Required")
      .matches(/^\d+$/, "Please Enter a whole number")
      .test(
        "CheckNotZero",
        `Minimum guests ${minimumGuests}`,
        (value) => minimumGuests <= parseInt(value)
      ),
  });

  return (
    <FancyBorder>
      <TextDivider fill="#d19851" className={textStyle.head}>
        Add number of guests
      </TextDivider>

      <Formik
        initialValues={{
          numberOfGuests: `${numberOfGuests}`,
        }}
        validationSchema={Validation}
        onSubmit={(values, { setSubmitting }) =>
          handleSubmit(values, setSubmitting)
        }
      >
        {({ isSubmitting }) => {
          return (
            <Form className={styles.form}>
              <label className={styles.formLabel} htmlFor="numberOfGuests">
                Number of Guests
              </label>
              <Field
                className={styles.formField}
                placeholder="0"
                type="text"
                name="numberOfGuests"
              />
              <ErrorMessage
                className={styles.formError}
                name="numberOfGuests"
                component="div"
              />
              <button className="primary-btn " type="submit">
                Submit
              </button>
            </Form>
          );
        }}
      </Formik>
      {/* <form>
        <label htmlFor="numberOfGuests">NUmber Of guests</label>
        <input name="numberOfGuests" type="number"/>
      </form>
      <button
        onClick={() => {
          setNumberOfGuests(numberOfGuests + 1);
        }}
      >
        CLICK
      </button> */}
    </FancyBorder>
  );
};

export default Guests;
