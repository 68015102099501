import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { animated, useSpring, config } from "react-spring";
import TextFloralBg from "../components/SVG/TextFloralBg";
import * as styles from "../styles/menu/mainMenu.module.css";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import MenuBrowse from "../components/menu/MenuBrowse";
import Summary from "../components/orderbuild/Summary";
import FloatButton from "../components/menu/FloatButton";
import Instructions from "../components/orderbuild/Instructions";
import ProductList from "../components/menu/ProductList";
import Guests from "../components/orderbuild/Guests";
import { useSelector, useDispatch } from "react-redux";
import { AddMenu } from "../Redux/actions/CartActions";
import { ToggleCart } from "../Redux/actions/ToggleCart";

const RemoveItem = (courseName, productToRemove, menu, setMenu) => {
  let newList = menu[courseName].filter(
    (menuItem) => menuItem.name !== productToRemove.name
  );
  //Update state
  setMenu((state) => {
    return {
      ...state,
      ...{ [courseName]: newList },
      totalItems: state.totalItems - 1,
      //Total of each individual Products Price per Person
      total: state.total - productToRemove.pricePerPerson,
    };
  });
  //Update Style
  CardStyleChange(document.querySelector(productToRemove.elementId));
};

const AppendItem = (courseName, productToAdd, setMenu) => {
  setMenu((state) => {
    return {
      //Copy old state
      ...state,
      //copy Existing course products and add new product
      ...{
        [courseName]: [
          ...state[courseName],
          {
            name: productToAdd.name,
            id: productToAdd.id,
            elementId: productToAdd.elementId,
            pricePerPerson: productToAdd.pricePerPerson,
          },
        ],
      },
      totalItems: state.totalItems + 1,
      //Total of each individual Products Price per Person
      total: state.total + productToAdd.pricePerPerson,
    };
  });
  //Update The style
  CardStyleChange(document.querySelector(productToAdd.elementId));
};

const CreateNewCourse = (courseName, productToAdd, setMenu) => {
  setMenu((state) => {
    return {
      ...state,
      totalItems: state.totalItems + 1,
      [courseName]: [
        {
          name: productToAdd.name,
          id: productToAdd.id,
          elementId: productToAdd.elementId,
          pricePerPerson: productToAdd.pricePerPerson,
        },
      ],
      //Total of each individual Products Price per Person
      total: state.total + productToAdd.pricePerPerson,
    };
  });
  //Update style
  CardStyleChange(document.querySelector(productToAdd.elementId));
};

const CardStyleChange = (element) => {
  //If cardSelected class is there then remove it(Deselect item)
  if (element.classList.contains(`${styles.cardSelected}`)) {
    element.classList.remove(`${styles.cardSelected}`);
  } else {
    //If cardSelected class absent then add it(Select item)
    element.classList.add(`${styles.cardSelected}`);
  }
};

const Card = ({
  courseInfo,
  product,
  setMenu,
  menu,
  index,
  cardSelected = false,
}) => {
  const elementId = product.name.replace(/\s/g, "");
  const handleClick = (item) => {
    //If cardSelected class is there then remove it(Deselect item)
    const keys = Object.keys(menu);
    let courseName = item.course;
    let productToAdd = item.product;

    productToAdd.elementId = `#${elementId}`;
    //Check if Meal course exists
    if (keys.includes(courseName)) {
      //Check if product exists
      let exists = false;
      menu[courseName].every((product) => {
        if (product.name === productToAdd.name) {
          exists = true;
          return false;
        }
        return true;
      });
      if (exists) {
        //If product already exists then filter out the product to remove from list
        RemoveItem(courseName, productToAdd, menu, setMenu);
      } else {
        //If product does not exist, simply add the new product(No filtering happening here)
        AppendItem(courseName, productToAdd, setMenu);
      }
    } else {
      //If Meal course does not exist then create the course and add the product
      CreateNewCourse(courseName, productToAdd, setMenu);
    }
  };

  return (
    <React.Fragment>
      <div
        id={`${elementId}`}
        key={index}
        onClick={(e) => {
          handleClick({
            e: e,
            course: courseInfo.courseName,
            product: product,
          });
        }}
        className={`${styles.card} ${cardSelected && styles.cardSelected}`}
      >
        <GatsbyImage
          className={styles.cardImg}
          image={getImage(product.img)}
          alt=""
        />
        <div className={styles.cardInfo}>
          <h5 key={index}>{product.name}</h5>
          <div className={styles.divider} />
          <p>{product.description}</p>
          <div className={styles.buttonContainer}>
            <button className={`${styles.button} ${styles.addButton}`}>
              Add
            </button>
            <button className={`${styles.button} ${styles.removeButton}`}>
              remove
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const addToCart = (menu, dispatch) => {
  // delete(info.ref);
  dispatch(AddMenu(menu));
  dispatch(ToggleCart());
};

function Orderbuild({ data }) {
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const operations = useSelector((state) => state.operations);
  const menuProducts = cart.MenuProducts;

  const [menu, setMenu] = useState({ totalItems: 0, guests: 0, total: 0 });

  //Get FilteredProducts
  useEffect(() => {
    const formattedData = [];
    //Get all the meal courses and their respective ID's
    data.allStrapiMealCourses.edges.forEach((edge, index) => {
      formattedData.push({
        courseName: edge.node.name,
        courseId: edge.node.strapiId,
        menuInfo: [],
      });
    });
    //Match each MenuCategory with its respective Meal Course and include its product info
    data.allStrapiMenuCategory.edges.forEach((edge) => {
      formattedData.forEach((item) => {
        if (item.courseId === edge.node.meal_course.id) {
          item["menuInfo"] = [
            ...item["menuInfo"],
            {
              menuCategory: edge.node.name,
              products: edge.node.products,
            },
          ];
        }
      });
    });
    setFilteredData(formattedData);
  }, []);

  const posProps = useSpring({
    from: {
      optionsBottom: `5rem`,
      cartButtonBottom: `-15rem`,
      cartButtonPointerEvents: `none`,
      cartButtonOpacity: 0,
    },
    to: {
      optionsBottom:
        menu.guests === 0 || menu.totalItems === 0 ? `5rem` : `8.5rem`,
      cartButtonBottom:
        menu.guests === 0 || menu.totalItems === 0 ? `-5rem` : `0rem`,
      cartButtonOpacity: menu.guests === 0 || menu.totalItems === 0 ? 0 : 1,
      cartButtonPointerEvents:
        menu.guests === 0 || menu.totalItems === 0 ? `none` : `auto`,
    },
    config: config.wobbly,
  });

  useEffect(() => {
    const MenuKeys = Object.keys(menuProducts);
    if (MenuKeys.length > 0) {
      setMenu(menuProducts);
    } else {
      setMenu({ totalItems: 0, guests: 0, total: 0 });
    }
  }, [menuProducts]);
  const [floatShow, setFloatShow] = useState(true);
  //To mount and unmount the different menuBrowse and Summary helpers
  useEffect(() => {
    window.innerWidth <= 992 ? setFloatShow(true) : setFloatShow(false);
    const handleResize = () => {
      window.innerWidth <= 992 ? setFloatShow(true) : setFloatShow(false);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Layout
      title={data.strapiSeo.PageTitle}
      description={data.strapiSeo.PageDescription}
    >
      <React.Fragment>
        <animated.div
          className={styles.options}
          style={{ bottom: posProps.optionsBottom }}
        >
          {floatShow && (
            <FloatButton
              menuSelectCloseCard={true}
              name="browse menu"
              childProps={{ data: filteredData }}
              Child={MenuBrowse}
            />
          )}

          <FloatButton
            menuSelectCloseCard={true}
            name="Add Guests"
            childProps={{
              setMenu: setMenu,
              numberOfGuests: menu.guests,
            }}
            buttonStyle={{ display: "block" }}
            number={menu.guests}
            Child={Guests}
          />
          {floatShow && (
            <FloatButton
              menuSelectCloseCard={true}
              name="your menu"
              childProps={{
                filteredData: filteredData,
                menu: menu,
                setMenu: setMenu,
                RemoveItem: RemoveItem,
              }}
              number={menu.totalItems}
              Child={Summary}
            />
          )}
        </animated.div>

        <animated.div
          style={{
            bottom: posProps.cartButtonBottom,
            opacity: posProps.cartButtonOpacity,
            pointerEvents: posProps.cartButtonPointerEvents,
          }}
          className={styles.CartAddButton}
        >
          <div onClick={() => addToCart(menu, dispatch)}>
            {Object.keys(cart.MenuProducts).length > 0
              ? "Update cart"
              : "Add to cart"}
          </div>
        </animated.div>
        <animated.div
          style={{
            bottom: posProps.cartButtonBottom,
            opacity: posProps.cartButtonOpacity,
            // pointerEvents: "none",
            cursor: "auto",
          }}
          className={styles.Estimate}
        >
          <div style={{fontSize: '2.8rem'}}>₹ {` ${(menu.total * menu.guests).toFixed(2)}`}</div>
        </animated.div>
        <Instructions operating={operations.operating} />

        <div className={styles.container}>
          {!floatShow && (
            <div className={styles.sideHelper}>
              <MenuBrowse data={filteredData} />
            </div>
          )}

          <ProductList
            menuProducts={menuProducts}
            filteredData={filteredData}
            Card={Card}
            cardProps={{ setMenu: setMenu, menu: menu }}
            disabled={operations.operating ? false : true}
          />

          {!floatShow && (
            <div className={styles.sideHelper}>
              <Summary
                TextFloralBg={TextFloralBg}
                filteredData={filteredData}
                menu={menu}
                setMenu={setMenu}
                RemoveItem={RemoveItem}
              />
            </div>
          )}
        </div>
      </React.Fragment>
    </Layout>
  );
}

export const query = graphql`
  query orderBuild {
    allStrapiMealCourses {
      edges {
        node {
          strapiId
          name
          id
          menu_categories {
            id
          }
        }
      }
    }
    allStrapiMenuCategory {
      edges {
        node {
          name
          meal_course {
            id
          }
          products {
            id
            pricePerPerson
            name
            description

            img {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 350)
              }
            }
          }
        }
      }
    }
    strapiMenuBuildPage {
      menuCartImage {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 350)
        }
      }
    }
    strapiSeo(NameOfPage: { eq: "customMenu" }) {
      PageDescription
      PageTitle
    }
  }
`;

export default Orderbuild;
