import React from "react";
import TextDivider from "../SVG/TextDivider1";
import FancyBorder from "../menu/FancyBorder";
import * as menuBrowseStyles from "../../styles/menu/menuBrowse.module.css";
import * as styles from "../../styles/orderbuild/summary.module.css";
import Tree from "../menu/Tree";

function Summary({
  filteredData,
  menu,
  setMenu,
  RemoveItem,
  className,
  onNameClickFunc,
  height
}) {
  return (
    <FancyBorder height={height}>
      <div className={className && `${className}`}>
        <TextDivider fill="#d19851" className={styles.head}>
          Your Menu
        </TextDivider>
        {filteredData.map((courseInfo, index) => {
          return (
            <span key={index}>
              <Tree
                fill="#d19851"
                nameClick={onNameClickFunc}
                name={`${courseInfo.courseName}`}
                defaultOpen
              >
                {/* courseName exists and its products are greater than 0 */}
                {menu[courseInfo.courseName] &&
                menu[courseInfo.courseName].length > 0 ? (
                  menu[courseInfo.courseName].map((product, index) => {
                    return (
                      <span key={index}>
                        <Tree
                          nameClick={onNameClickFunc}
                          fill="#d19851"
                          iconClick={() =>
                            RemoveItem(
                              courseInfo.courseName,
                              product,
                              menu,
                              setMenu
                            )
                          }
                          iconStyle={{ fill: "red", opacity: 1 }}
                          className={menuBrowseStyles.productName}
                          name={`${product.name}`}
                        />
                      </span>
                    );
                  })
                ) : (
                  <span key={index}>
                    <Tree
                      fill="#d19851"
                      className={menuBrowseStyles.productName}
                      name={`No Products Selected`}
                      attachHref={false}
                    />
                  </span>
                )}
              </Tree>
            </span>
          );
        })}
      </div>
    </FancyBorder>
  );
}

export default Summary;
